<template>
  <div :class="['mask', { opacity: type == 1 }]" v-if="isShow">
    <div
      :class="[
        'popup',
        'positionCenter',
        { type2: type == 1 },
        { type3: type == 2 },
      ]"
    >
      <div class="title" v-if="type == 1 || type == 2">提示</div>
      <div :class="['content', { message: type == 0 }]">
        <img src="@/assets/full.png" v-if="type == 1" alt />
        <span>{{ title }}</span>
      </div>
      <button v-if="type == 1" @click="confirm">确认</button>

      <div class="btns" v-if="type == 2">
        <div class="confirm" @click="confirm">确定</div>
        <div class="cancal" @click="isShow = false">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //显示出消息
      isShow: false,
      //弹出的消息内容
      title: "",
      callback: () => {},
      type: 0,
    };
  },
  methods: {
    confirm() {
      this.callback();
      this.isShow = false;
    },
  },
};
</script>

<style lang="less" scoped>
.mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.opacity {
  background-color: rgba(0, 0, 0, 0.8);
}
.popup {
  z-index: 100;
  width: max-content;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  line-height: 50px;
  color: #fff;
  border-radius: 10px;
  padding: 0 20px;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  .content.message {
    display: flex;
    align-items: center;
    height: 100%;
    span {
      font-size: 16px;
      line-height: 16px;
    }
  }
}

.type2 {
  width: 312.5px;
  height: 156.25px;
  background: #ffffff;
  border-radius: 10.42px;
  button {
    width: 243.06px;
    height: 32.99px;
    background: linear-gradient(#0aa7f4 0%, #0adbf4 100%);
    border-radius: 16.67px;
    border: 0;
    margin-top: 30px;

    font-size: 14.58px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    line-height: 10.42px;
  }
  .title {
    font-size: 16.67px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: center;
    color: #333333;
  }
  > .content {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 5px;
      width: 20px;
      height: 20px;
    }
    span {
      font-size: 13.89px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: 400;
      text-align: left;
      color: #333333;
      line-height: 10.42px;
    }
  }
}
.type3 {
  width: 312.5px;
  height: 156.25px;
  background: #ffffff;
  border-radius: 10.42px;
  position: relative;
  .title {
    font-size: 16.67px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: center;
    color: #333333;
  }
  > .content {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      display: block;
      width: 150px;
      font-size: 13.89px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: 400;
      text-align: center;
      color: #333333;
      line-height: 20.42px;
    }
  }
  .btns {
    position: absolute;
    display: flex;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 45px;
    .confirm,
    .cancal {
      font-size: 14.58px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: 400;
      text-align: center;
      color: #333333;
    }
    .confirm {
      color: #0aa7f4;
    }
    .confirm {
      width: 50%;
      border-right: 1px solid #ddd;
      border-top: 1px solid #ddd;
    }
    .cancal {
      border-top: 1px solid #ddd;
      width: 50%;
    }
  }
}
</style>